import React from 'react';
import './WhoWeAre.css';
import teamImage from '../images/Rectangle 26.png'; // Replace with your actual image path
import teamImage2 from '../images/Rectangle 25 (1).png'

const WhoWeAre = () => {
    return (
        <section className="who-we-are">
            <h2>Who are We?</h2>
            <div className="who-we-are-container">
                <div className="image-stack">
                    <div className="image-stack__item image-stack__item--top">
                        <img src={teamImage} alt="Team" />
                    </div>
                    <div className="image-stack__item image-stack__item--middle">
                        <img src={teamImage2} alt="Team" />
                    </div>
                    <div className="image-stack__item image-stack__item--bottom">
                        <img src={teamImage2} alt="Team" />
                    </div>
                </div>
                <div className="who-we-are-text">
                    
                    <h3>The core mission behind all our work</h3>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit metus ut tortor purus tincidunt sed lectus ut eros, turpis tincidunt id.
                    </p>
                    <div className="stats">
                        <div className="stat">
                            <h4>330 +</h4>
                            <p>Companies helped</p>
                        </div>
                        <div className="stat">
                            <h4>230 +</h4>
                            <p>Revenue generated</p>
                        </div>
                    </div>
                    <button className="btn-primary">Be our partners</button>
                </div>
            </div>
        </section>
    );
};

export default WhoWeAre;
