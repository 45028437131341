import React from 'react';
import { Helmet } from 'react-helmet';
import './Home.css';
import WhatOffer from './WhatOffer';
import LoveWeb5 from './LoveWeb5';
import HowWorks from './HowWorks';
// import FullCycle from './FullCycle';
import BlogHome from './BlogHome';
import TechStack from './TechStack';
import ContactUs from './ContactForm'
import TestimonialCarousel from './TestimonialCarousel';
import ContactUsForm2 from './ContactUsForm2';
import IndustriesWeServe from './IndustriesWeServe';
import OurTeam from './OurTeam';
import WaveAnimation from './WaveAnimation';
import OurNetworks from './OurNetworks';
import LatestBlogs from './LatestBlogs';

const Home = () => {
    return (
        <div className="home">
            <Helmet>
                <title>Innovative Enterprise Solutions: Web5solution Blockchain,
Metaverse & AI Expertise
</title>
                <meta name="description" content="Explore new solutions for the company with
Web5solution. We are focused on technologies such as Blockchain,
Metaverse, and AI, finding innovative solutions for your business." />
                <meta name="keywords" content="web5solution,web5, Blockchain, Crypto,blockchain development company, blockchain development agency, blockchain and development, blockchain app development, metaverse development company, generative ai services, defi development company, cto crypto meaning, cto meaning crypto, what does cto mean in crypto, understand the ai technology stack, rapidminer studio generative ai document, promote nft collection" />
            </Helmet>
            <WhatOffer />
            <LoveWeb5 />
            <HowWorks />
            <IndustriesWeServe/>
            <OurTeam/>
            {/* <WaveAnimation/> */}
            {/* <FullCycle/> */}
            {/* <TechStack /> */}
            <OurNetworks/>
            {/* <BlogHome /> */}
            <LatestBlogs/>
            <TestimonialCarousel/>
            <ContactUsForm2/>
        </div>
    );
};

export default Home;
