import React, { useState, useEffect } from 'react';
import './Header.css';
import compImage from '../images/rightimage.png';
import contactImage from '../images/Rectangle_1781__1_-removebg-preview.png';
// import serviceImage from '../images/Rectangle 1782.png';
import serviceImage from '../images/our services.png';
// import aboutImage from '../images/blockssss-removebg-preview.png';
import aboutImage from '../images/about web5 solution.png';
import blogsImage from '../images/blogs-right.png';
import { useNavigate, useLocation } from 'react-router-dom';
import CustomButton from './CustomButton';

const Header = () => {
    const texts = ["Blockchain Solutions", "Web3 Marketing", "Fundraising Solutions", "DEX Development"];
    const [currentTextIndex, setCurrentTextIndex] = useState(0);
    const [isVisible, setIsVisible] = useState(true);

    const navigate = useNavigate();
    const location = useLocation();

    const handleClick = () => {
        navigate('/contact');
    };

    useEffect(() => {
        const intervalId = setInterval(() => {
            setIsVisible(false); 
            setTimeout(() => {
                setCurrentTextIndex(prevIndex => (prevIndex + 1) % texts.length);
                setIsVisible(true);
            }, 500); 
        }, 3000); 

        return () => clearInterval(intervalId);
    }, [texts.length]);

    let headerContent;
    let mobileBgClass;

    switch (location.pathname) {
        case '/':
            headerContent = {
                title: "Exceeding Outlooks",
                description: "Empower your digital journey with our next-gen blockchain solution, ensuring unparalleled security, seamless connectivity, and the efficiency needed for a truly interconnected world",
                image: compImage
            };
            mobileBgClass = 'home-bg';
            break;
        case '/services':
            headerContent = {
                title: "Our Services",
                description: "Discover tailored blockchain solutions, from NFT marketplaces to mobile apps. We offer comprehensive services designed to meet your unique business needs and enhance your digital presence.",
                image: serviceImage
            };
            mobileBgClass = 'services-bg';
            break;
        case '/about':
            headerContent = {
                title: "About Web5 Solution",
                description: "Discover our team of passionate innovators dedicated to setting new quality standards in blockchain development. We prioritize client needs and foster long-lasting relationships through exceptional service.",
                image: aboutImage
            };
            mobileBgClass = 'about-bg';
            break;
        case '/portfolio':
            headerContent = {
                title: "Our Projects",
                description: "Browse our portfolio of successful blockchain projects that drive business growth and innovation. See how we’ve helped clients achieve their goals with our expertise and creativity.",
                image: aboutImage
            };
            mobileBgClass = 'portfolio-bg';
            break;
        case '/contact':
            headerContent = {
                title: "Get in Touch",
                description: "Reach out for expert guidance and support. We're here to help you succeed in your blockchain journey with personalized solutions and dedicated assistance.",
                image: contactImage
            };
            mobileBgClass = 'contact-bg';
            break;
            case '/blogs':
                headerContent={
                    title:"Latest Insights",
                    description:"Explore our blog for the newest trends and insights in blockchain and digital innovation. Stay informed with expert analysis and practical advice to keep you ahead of the curve.",
                    image:blogsImage
                };
                mobileBgClass ='blogs-bg'
break;
        default:
            headerContent = {
                title: "Exceeding Outlooks",
                description: "Empower your digital journey with our next-gen blockchain solution, ensuring unparalleled security, seamless connectivity, and the efficiency needed for a truly interconnected world",
                image: compImage
            };
            mobileBgClass = 'home-bg';
            break;
    }

    return (
        <div className={`header-container ${mobileBgClass}`}>
            <div className="header-left1">
                <div className="header-title">
                    <h1>{headerContent.title}</h1>
                </div>
                <div className='mobile-heading'>
                    <p>Next-Gen Blockchain<br /> solution for a <br />connected world.</p>
                </div>
                {location.pathname === '/' && (
                    <div className="header-highlight">
                        <span className="header-in">In</span>
                        <span className={`header-highlight-text ${isVisible ? 'text-in' : 'text-out'}`}>
                            {texts[currentTextIndex]}
                        </span>
                    </div>
                )}
                <div className="header-description">
                    <p>{headerContent.description}</p>
                </div>
                {location.pathname === '/' && (
                    <CustomButton buttonText="Consult Our Experts" onClick={handleClick} />
                )}
            </div>
            <div className='header-right'>
                <img src={headerContent.image} alt={headerContent.title} />
            </div>
        </div>
    );
};

export default Header;
