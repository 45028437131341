
import React from 'react';
import './TechStack.css';
import donationImage from '../images/donation.svg';
import blockChainImage from '../images/blockchain.svg';
import etheriumImage from '../images/ethereum.svg';
import nearImage from '../images/near.svg';
import tezosImage from '../images/tezos.svg';
import poaImage from '../images/poa-network-poa.svg';
import telosImage from '../images/telos.png.png';
import kalImage from '../images/klaytn-5382300-4498172.webp';
import tonImage from '../images/ton_symbol.webp';
import rustImage from '../images/rust.png';
import solidityImage from '../images/solidity.png';
import zeptaImage from '../images/zetachain-icon.svg';
import solImage from '../images/solana-sol.svg';
import harmonyImage from '../images/Harmony.webp';
import truffleImage from '../images/truffle.png';
import hardhatImage from '../images/hardhat.png';
import webImage from '../images/webass.jpeg';
import chainImage from '../images/chain-removebg-preview.png';
import oracleImage from '../images/oracle-cloud-logo.png';
import ibmImage from '../images/ibm.png';
import amazonImage from '../images/amazon-removebg-preview.png';
import vyperImage from '../images/vyper.png';
import moveImage from '../images/move.png';
import ciaroImage from '../images/ciaro-removebg-preview.png';
import zeplineImage from '../images/zepline-removebg-preview (1).png';
import embarkImage from '../images/embark.png';
import brownieImage from '../images/brownie.png';
import waffleImage from '../images/waffle-removebg-preview.png';
import whiteImage from '../images/whiteblock_logo.jpeg';
// SVG icons for each row
const iconsRow1 = [donationImage, blockChainImage,etheriumImage,tezosImage, nearImage, poaImage,zeptaImage,solImage,telosImage,kalImage,tonImage, harmonyImage,amazonImage,ibmImage ,oracleImage,chainImage,];
const iconsRow2 = [solidityImage,webImage,rustImage,vyperImage,moveImage,ciaroImage,truffleImage,hardhatImage,zeplineImage, embarkImage,brownieImage,waffleImage,solImage,whiteImage ];


const IconRow = ({ icons, direction, heading }) => {
  return (
    <div className={`icon-row ${direction}`}>
      <span className={`heading fade-out`}>{heading}</span>
      <div className="icons-container">
        <div className="icon-group">
          {icons.map((icon, index) => (
            <div key={index} className="icon" style={{ width: '40px', height: '40px' }}>
             <img src={icon} alt="icon" style={{ width: '40px', height: '40px' }} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const TechStack = () => {
  return (
    <div className="icon-container">
      <h1>Our Technology Expertise</h1>
      <IconRow icons={iconsRow1} direction="left-to-right" heading="Blockchain Frameworks & Cloud " />
      <IconRow icons={iconsRow2} direction="right-to-left" heading="Smart Contract Languages & Frameworks" />
    </div>
  );
};

export default TechStack;
