import React from 'react';
import './HowWorksCards.css';

const HowWorksCards = ({ title, subtitle, content, image }) => {
  return (
    <div className="hw-card">
      <div className='hw-image'>
      <h3 className='hw-subtitle'>{subtitle}</h3>
      <img src={image} alt={title} className="card-image" />
      </div>
      <div className="hw-card-content">
        <h2>{title}</h2>
        <p>{content}</p>
      </div>
    </div>
  );
};

export default HowWorksCards;
