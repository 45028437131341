import React, { useState, useEffect } from 'react';
import { FaPhone, FaEnvelope, FaMapMarkerAlt, FaAddressCard, FaInstagram, FaFacebook, FaTwitter, FaLinkedin, FaYoutube, FaWhatsapp, FaMedium} from 'react-icons/fa';
import './Footer.css';
import frameImage from '../images/Frame.svg';
import deckPdf from '../images/Web5 Pitch Deck (1).pdf';
import imageImage from '../images/image2.png';
import EmailSubscription from './EmailSubscription';
import CopyRight from './CopyRight';

function Footer() {
  const texts = ["Blockchain Solutions", "Web3 Marketing", "Fundraising Solutions", "DEX Development"];
  const [currentTextIndex, setCurrentTextIndex] = useState(0);
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setIsVisible(false); // Start hiding current text
      setTimeout(() => {
        setCurrentTextIndex(prevIndex => (prevIndex + 1) % texts.length); // Update to next text
        setIsVisible(true); // Show next text
      }, 500); // Adjust this timing to match CSS animation duration
    }, 3000); // Change text every 3 seconds

    return () => clearInterval(intervalId);
  }, [texts.length]);

  const handleNavigation = (path) => {
    window.location.href = path;
  };

  const handlePdfOpen = () => {
    window.open(deckPdf, '_blank'); // Open the PDF in a new tab
  };

  const handleIconClick = (platform) => {
    switch (platform) {
      case 'instagram':
        window.open('https://www.instagram.com/web5_solution/', '_blank');
        break;
      case 'facebook':
        window.open('https://www.facebook.com/web5solution', '_blank');
        break;
      case 'medium':
        window.open('https://medium.com/@web5solutions', '_blank');
        break;
      case 'linkedin':
        window.open('https://www.linkedin.com/company/web5-solution/mycompany/', '_blank');
        break;
      case 'twitter':
        window.open('https://x.com/Web5solution', '_blank');
        break;
      case 'whatsapp':
        // You can handle WhatsApp click based on your application's logic
        break;
      case 'tiktok':
        // You can handle TikTok click based on your application's logic
        break;
      case 'phone':
        window.open('tel:+919991068666');
        break;
      case 'email':
        window.open('mailto:info@web5solution.com');
        break;
      case 'address':
        window.open('https://www.google.com/maps/place/Tower+B3,+Spaze+i-Tech+Park,+Sector+49,+Gurugram,+Haryana+122018/@28.41274,77.0435151,19z/data=!3m1!4b1!4m6!3m5!1s0x390d229aba105daf:0x74b99d1aa613bad5!8m2!3d28.4127388!4d77.0441588!16s%2Fg%2F11fs0gpnhf?entry=ttu', '_blank');
        break;
      default:
        break;
    }
  };

  return (
    <div className='footer-copy'>
      <div className='footer'>
        <h3>Exceeding Outlooks</h3>
        <h2>In <span className={`header-highlight-text ${isVisible ? 'text-in' : 'text-out'}`} >{texts[currentTextIndex]}</span> </h2>
        
        <div className='footer-mainCard'>
          <div className='footer-card1'>
            {/* <img src={imageImage} alt=''/> */}
            <div className='footer-card01'>
              <h5 className='footer-card1-h1'>Web5solution</h5>
              <p>Web5 Solutions: Building the Decentralized Future. Next-gen blockchain solutions for a connected world.</p>
            </div>
          </div>
          <div className='footer-card2'>
            <h4>Get In Touch</h4>
            <p onClick={() => handleIconClick('email')}><span><FaEnvelope/></span>info@web5solution.com</p>
            <p ><span><FaAddressCard/></span>19A, Sector 49,<br/>      Gurugram 122018,<br/>      Haryana, India</p>
          </div>
          <div className='footer-card3'>
            <h4>About</h4>
            <p onClick={() => handleNavigation('/blogs')}>Blogs</p>
            <p onClick={() => handleNavigation('/services')}>List of Services</p>
            <p onClick={() => handleNavigation('/contact')}>Contact</p>
            <p onClick={handlePdfOpen}>Pitch Deck</p>
          </div>
          <div className='footer-card4'>
            <h4>Subscribe to Our Newsletter</h4>
            <hr/>
            <EmailSubscription/>
            <div className='all-icons'>
              <span onClick={() => handleIconClick('instagram')}><FaInstagram /></span>
              <span onClick={() => handleIconClick('facebook')}><FaFacebook /></span>
              <span onClick={() => handleIconClick('medium')}><FaMedium /></span>
              <span onClick={() => handleIconClick('linkedin')}><FaLinkedin /></span>
              <span onClick={() => handleIconClick('twitter')}><FaTwitter /></span>
              {/* <span onClick={() => handleIconClick('whatsapp')}><FaWhatsapp /></span> */}
              {/* <span onClick={() => handleIconClick('tiktok')}><FaTiktok /></span> */}
            </div>
          </div>
        </div>
      </div>
      <CopyRight/>
    </div>
  );
}

export default Footer;
