import React from 'react'
import './ContactUs.css'
import imageImage from '../images/image2.png';
import globeImage from '../images/start.png';
import indImage from '../images/india-map.svg';
import mapImage from '../images/mapdubai.png';
import singImage from '../images/singapore-map (1).svg';
import ContactForm from './ContactForm'
import OurTeam from './OurTeam'
import { Helmet } from 'react-helmet';
import ServiceInternalPage from './ServiceInternalPage';

const ContactUs = () => {
  return (
    <div className='contactUs'>
                        <Helmet>
                <title>Web5 Solution - contactUs</title>
                <meta name="description" content="Welcome to Web5 Solution - We offer great services!" />
                <meta name="keywords" content="web5solution,web5, Blockchain, Crypto" />
            </Helmet>
      <div className='form-div'>
        <div className='globe-div'> 
            <h3>Have a project in mind?</h3>
            <img src={imageImage} alt='' style={{width:"50px", height:"50px"}}/>
            <div className='globe-img'>
            <img src={globeImage} alt=''/>
            </div>
            
        </div>
<div className=''>
    <ContactForm/>
</div>
      </div>
      <div className='map-div'>
      <div className='mapdubai2'>
      <svg xmlns="http://www.w3.org/2000/svg" width="369" height="427" viewBox="0 0 369 427" fill="none">
        <path d="M368 426V79.7248L286.112 1H1V426H368Z" stroke="#22A8B3"/>
      </svg>
      <div className='mapdubai-container'>
      <img src={indImage} alt='' className='mapImage' style={{marginTop:"20px"}}/>
      <div className='map-details'>
        <h5>India</h5>
      </div>
      </div>

    </div>
        <div className='mapdubai2'>
      <svg xmlns="http://www.w3.org/2000/svg" width="369" height="427" viewBox="0 0 369 427" fill="none">
        <path d="M368 426V79.7248L286.112 1H1V426H368Z" stroke="#22A8B3"/>
      </svg>
      <div className='mapdubai-container'>
      <img src={singImage} alt='' className='mapImage'/>
      <div className='map-details'>
        <h5>Singapore</h5>
      </div>
      </div>

    </div>
        <div className='mapdubai2'>
      <svg xmlns="http://www.w3.org/2000/svg" width="369" height="427" viewBox="0 0 369 427" fill="none">
        <path d="M368 426V79.7248L286.112 1H1V426H368Z" stroke="#22A8B3"/>
      </svg>
      <div className='mapdubai-container'>
      <img src={mapImage} alt='' className='mapImage'/>
      <div className='map-details'>
        <h5>Dubai</h5>
      </div>
      </div>

    </div>
      </div>
      
      <OurTeam/>
      {/* <ServiceInternalPage/> */}
    </div>
  )
}

export default ContactUs
