import React, { useState } from 'react';
import './WhatOffer.css';
import customImage from '../images/Custom_Blockchain_Technology_3.jpg';
import nftImage from '../images/NFT 1.jpg';
import crowdImage from '../images/CROWD FUNDING.jpg';
import blockImage from '../images/Blockchain Payment Platform.png';
import finalImage from '../images/Financial Trading Platform.png';
import marketImage from '../images/Marketplace Development.png';
import daoImage from '../images/DAO Development.png';
import defiImage from '../images/DeFi Development.png';
import gameImage from '../images/GameFi Apps.png';
import bitImage from '../images/bitcoin.gif';

import CustomButton from './CustomButton';

const Offer = () => {
    const [showAllCards, setShowAllCards] = useState(false);

    const handleShowMoreOrLess = () => {
        setShowAllCards(!showAllCards);
    };

    const renderCards = () => {
        const cards = [
            {
                id: 3,
                leftHeader: '01 Custom Blockchain Technology Solutions We Deliver',
                rightHeader: 'Custom Blockchain Technology Solutions We Deliver',
                description: 'As a prominent blockchain app development company, we deliver custom blockchain solutions....',
                fullDescription: 'As a prominent blockchain app development company, we deliver custom blockchain solutions crafted specifically to cater to your business needs....',
                image: customImage,
            },
            {
                id: 4,
                leftHeader: '02 NFT Development',
                rightHeader: 'NFT Development',
                description: 'Our NFT Development services empower you to create, manage, and trade non-fungible tokens seamlessly....',
                fullDescription: 'Our NFT Development services empower you to create, manage, and trade non-fungible tokens seamlessly. We design robust platforms that ensure secure....',
                image: nftImage,
            },
            {
                id: 5,
                leftHeader: '03 Crowdfunding Platform Development',
                rightHeader: 'Crowdfunding Platform Development',
                description: 'We build custom crowdfunding platforms that streamline fundraising processes. Our solutions enable secure....',
                fullDescription: 'We build custom crowdfunding platforms that streamline fundraising processes. Our solutions enable secure, transparent, and efficient capital raising....',
                image: crowdImage,
            },
            {
                id: 6,
                leftHeader: '04 Blockchain Payment Platform',
                rightHeader: 'Blockchain Payment Platform',
                description: 'Our Blockchain Payment Platforms facilitate secure, instant transactions. We develop scalable solutions that integrate....',
                fullDescription: 'Our Blockchain Payment Platforms facilitate secure, instant transactions. We develop scalable solutions that integrate seamlessly with existing systems....',
                image: blockImage,
            },
            {
                id: 7,
                leftHeader: '05 Financial Trading Platform',
                rightHeader: 'Financial Trading Platform',
                description: 'Our Financial Trading Platforms provide advanced, secure environments for trading digital assets....',
                fullDescription: 'Our Financial Trading Platforms provide advanced, secure environments for trading digital assets. We design user-friendly interfaces and implement....',
                image: finalImage,
            },
            {
                id: 8,
                leftHeader: '06 Marketplace Development',
                rightHeader: 'Marketplace Development',
                description: 'We specialize in developing decentralized marketplaces that connect buyers and sellers directly....',
                fullDescription: 'We specialize in developing decentralized marketplaces that connect buyers and sellers directly. Our solutions ensure secure, transparent transactions....',
                image: marketImage,
            },
            {
                id: 9,
                leftHeader: '07 DAO Development',
                rightHeader: 'DAO Development',
                description: 'Our DAO Development services create decentralized autonomous organizations....',
                fullDescription: 'Our DAO Development services create decentralized autonomous organizations that enable transparent, democratic governance. We design and implement smart contracts....',
                image: daoImage,
            },
            {
                id: 10,
                leftHeader: '08 DeFi Development',
                rightHeader: 'DeFi Development',
                description: 'Our DeFi Development solutions revolutionize financial services by enabling decentralized lending....',
                fullDescription: 'Our DeFi Development solutions revolutionize financial services by enabling decentralized lending, borrowing, and trading. We build secure, scalable platforms....',
                image: defiImage,
            },
            {
                id: 11,
                leftHeader: '09 GameFi Apps',
                rightHeader: 'GameFi Apps',
                description: 'Our GameFi App development integrates blockchain technology with gaming, creating play-to-earn models....',
                fullDescription: 'Our GameFi App development integrates blockchain technology with gaming, creating play-to-earn models. We design immersive, rewarding gaming experiences....',
                image: gameImage,
            },
            // Add more cards as needed
        ];

        const cardsToRender = showAllCards ? cards : cards.slice(0, 3);

        return cardsToRender.map((card, index) => (
            <div key={index} className="offer-content">
                {card.id % 2 === 0 ? (
                    <>
                        <div className={`offer-right${card.id % 2 === 0 ? '-2' : ''}`}>
                            <h3 className="offer-right-header">{card.rightHeader}</h3>
                            <p className="offer-right-description">
                                <span className="mobile-view">{card.description}</span>
                                <span className="desktop-view">{card.fullDescription}</span>
                            </p>
                            <div className="image-wrapper">
                                <img src={card.image} alt="Hover to enlarge" className="image" />
                            </div>
                        </div>
                        <div className="offer-left-01">
                            <p className="offer-left-header">{card.leftHeader}</p>
                        </div>
                        <img src={bitImage} alt='' className='bitcoin'/>
                    </>
                ) : (
                    <>
                        <div className="offer-left">
                            <p className="offer-left-header">{card.leftHeader}</p>
                        </div>
                        <div className={`offer-right${card.id % 2 === 0 ? '-2' : ''}`}>
                            <h3 className="offer-right-header">{card.rightHeader}</h3>
                            <p className="offer-right-description">
                                <span className="mobile-view">{card.description}</span>
                                <span className="desktop-view">{card.fullDescription}</span>
                            </p>
                            <div className="image-wrapper">
                                <img src={card.image} alt="Hover to enlarge" className="image" />
                            </div>
                        </div>
                    </>
                )}
                {index % 2 === 0 ? (
                    <svg xmlns="http://www.w3.org/2000/svg" width="1000" height="163" viewBox="0 0 1161 163" fill="none">
                        <path d="M0.858459 1C32.0329 162 685.721 31.119 1160.16 162" stroke="black" strokeDasharray="2 2"/>
                    </svg>
                ) : (
                    <svg xmlns="http://www.w3.org/2000/svg" width="1000" height="163" viewBox="0 0 1147 163" fill="none">
                        <path d="M1146 1C1115.21 162 469.584 31.119 1.00003 162" stroke="black" strokeDasharray="2 2"/>
                    </svg>
                )}
            </div>
        ));
    };

    return (
        <div className="offer-component">
            <div className="offer-header">
                <h2>Innovative Blockchain Services</h2>
            </div>
            <div className="offer-subheader">
                <p>Revolutionize Your Digital Odyssey with Web5 Solution</p>
            </div>
            {renderCards()}
            <div className='whatOffer-btn'>
                <CustomButton onClick={handleShowMoreOrLess} showAllCards={showAllCards} buttonText="Know More"/>
            </div>
        </div>
    );
};

export default Offer;
