import './App.css';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Header from './components/Header';
import Home from './components/Home';
import Services from './components/Services';
import Navbar from './components/Navbar';
import Blog from './components/Blog';
import AboutUs from './components/AboutUs';
import Footer from './components/Footer';
import ContactUs from './components/ContactUs';
import BlogDetails from './components/BlogDetails';
import ScrollToTop from './components/ScrollToTop';
import CardDetail from './components/CardDetail';
import NotFound from './components/NotFound';

const App = () => {
  const location = useLocation();

  // Determine if we're on a detail page (blog or card detail page)
  const isDetailPage = location.pathname.startsWith('/blog/') || location.pathname.startsWith('/card/');

  return (
    <div className='App'>
      <Routes>
        {/* Only render valid pages */}
        <Route
          path="/"
          element={
            <>
              <Navbar />
              <Header />
              <Home />
              <Footer />
            </>
          }
        />
        <Route
          path="/services"
          element={
            <>
              <Navbar />
              <Header />
              <Services />
              <Footer />
            </>
          }
        />
        <Route
          path="/about"
          element={
            <>
              <Navbar />
              <Header />
              <AboutUs />
              <Footer />
            </>
          }
        />
        <Route
          path="/blogs"
          element={
            <>
              <Navbar />
              <Header />
              <Blog />
              <Footer />
            </>
          }
        />
        <Route
          path="/contact"
          element={
            <>
              <Navbar />
              <Header />
              <ContactUs />
              <Footer />
            </>
          }
        />
        <Route
          path="/card/:cardId"
          element={
            <>
              <Navbar />
              <CardDetail />
              <Footer />
            </>
          }
        />
        <Route
          path="/blog/:slug"
          element={
            <>
              <Navbar />
              <BlogDetails />
              <Footer />
            </>
          }
        />
        {/* Wildcard route for handling 404 */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
};

const AppWrapper = () => (
  <Router>
    <ScrollToTop /> {/* Scroll to top on navigation */}
    <App />
  </Router>
);

export default AppWrapper;




// import './App.css';
// import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
// import Header from './components/Header';
// import Home from './components/Home';
// import Services from './components/Services';
// import Navbar from './components/Navbar';
// import Blog from './components/Blog';
// import AboutUs from './components/AboutUs';
// import Footer from './components/Footer';
// import ContactUs from './components/ContactUs';
// import BlogDetails from './components/BlogDetails'; // Import the new BlogDetails component
// import ScrollToTop from './components/ScrollToTop'; // Import ScrollToTop component
// import CardDetail from './components/CardDetail';

// const App = () => {
//   const location = useLocation();

//   // Determine if we're on a detail page
//   const isDetailPage = location.pathname.startsWith('/blog/') || location.pathname.startsWith('/card/');

//   return (
//     <div className='App'>
//       <Navbar />
//       {!isDetailPage && <Header />}
//       <main className="main-content">
//         <Routes>
//           <Route path="/" element={<Home />} />
//           <Route path="/services" element={<Services />} />
//           <Route path="/about" element={<AboutUs />} />
//           <Route path="/blogs" element={<Blog />} />
//           <Route path="/contact" element={<ContactUs />} />
//           <Route path="/card/:cardId" element={<CardDetail />} />
//           <Route path="/blog/:slug" element={<BlogDetails />} /> {/* Updated route to use slug */}
//         </Routes>
//       </main>
//       <Footer />
//     </div>
//   );
// };

// const AppWrapper = () => (
//   <Router>
//     <ScrollToTop /> {/* Add ScrollToTop here */}
//     <App />
//   </Router>
// );

// export default AppWrapper;
