import React from 'react';
import './OurNetworks.css';
import donationImage from '../images/donation.svg';
import blockChainImage from '../images/blockchain.svg';
import etheriumImage from '../images/ethereum.svg';
import nearImage from '../images/near.svg';
import tezosImage from '../images/tezos.svg';
import poaImage from '../images/poa-network-poa.svg';
import telosImage from '../images/telos.png.png';
import kalImage from '../images/klaytn-5382300-4498172.webp';
import tonImage from '../images/ton_symbol.webp';
import rustImage from '../images/rust.png';
import solidityImage from '../images/solidity.png';
import zeptaImage from '../images/zetachain-icon.svg';
import solImage from '../images/solana-sol.svg';
import harmonyImage from '../images/Harmony.webp';
import truffleImage from '../images/truffle.png';
import hardhatImage from '../images/hardhat.png';
import webImage from '../images/webass.jpeg';
import chainImage from '../images/chain-removebg-preview.png';
import oracleImage from '../images/oracle-cloud-logo.png';
import ibmImage from '../images/ibm.png';
import amazonImage from '../images/amazon-removebg-preview.png';
import vyperImage from '../images/vyper.png';
import moveImage from '../images/move.png';
import ciaroImage from '../images/ciaro-removebg-preview.png';
import zeplineImage from '../images/zepline-removebg-preview (1).png';
import embarkImage from '../images/embark.png';
import brownieImage from '../images/brownie.png';
import waffleImage from '../images/waffle-removebg-preview.png';
import whiteImage from '../images/whiteblock_logo.jpeg';
import { motion } from "framer-motion";

function OurNetworks() {
  const iconsRow1 = [
    { name: 'Donation', image: donationImage },
    { name: 'Blockchain', image: blockChainImage },
    { name: 'Ethereum', image: etheriumImage },
    { name: 'Tezos', image: tezosImage },
    { name: 'Near', image: nearImage },
    { name: 'POA', image: poaImage },
    { name: 'ZetaChain', image: zeptaImage },
    { name: 'Solana', image: solImage },
    { name: 'Telos', image: telosImage },
    { name: 'Klaytn', image: kalImage },
    { name: 'TON', image: tonImage },
    { name: 'Harmony', image: harmonyImage },
    { name: 'AMB', image: amazonImage },
    { name: 'IBM', image: ibmImage },
    { name: 'OBP', image: oracleImage },
    { name: 'Chainlink', image: chainImage }
  ];
  
  const iconsRow2 = [
    { name: 'Solidity', image: solidityImage },
    { name: 'WebAssembly', image: webImage },
    { name: 'Rust', image: rustImage },
    { name: 'Vyper', image: vyperImage },
    { name: 'Move', image: moveImage },
    { name: 'Truffle', image: truffleImage },
    { name: 'Hardhat', image: hardhatImage },
    { name: 'OpenZeppelin', image: zeplineImage },
    { name: 'Embark', image: embarkImage },
    { name: 'Brownie', image: brownieImage },
    { name: 'Waffle', image: waffleImage },
    { name: 'Solana', image: solImage },
    { name: 'Whiteblock', image: whiteImage }
  ];

  return (
    <>

    <div className='ourNetwork'>
    <h2 className='ourNetworks-heading'>Revolutionizing the Industry with Persistent
Blockchain Ingenuity
</h2>
      <div className='ourNetwork-main'>
        <div>
          <h5 >Blockchain Frameworks & Cloud</h5>
        </div>
        <div className='marquee-container'>
          <motion.div
            initial={{ x: 0 }}
            animate={{ x: "-100%" }}
            transition={{ 
              duration: 120, 
              repeat: Infinity, 
              ease: 'linear' 
            }}
            className='marquee-div'
          >
            {iconsRow1.concat(iconsRow1).map((icon, index) => (
              <div key={index} className='icon-container'>
                <img 
                  src={icon.image} 
                  className={`marquee-img ${icon.name.toLowerCase().replace(/ /g, '-')}`} 
                  alt={icon.name} 
                />
                <span className='icon-name'>{icon.name}</span>
              </div>
            ))}
          </motion.div>
          <motion.div
            initial={{ x: 0 }}
            animate={{ x: "-100%" }}
            transition={{ 
              duration: 120, 
              repeat: Infinity, 
              ease: 'linear' 
            }}
            className='marquee-div'
          >
            {iconsRow1.concat(iconsRow1).map((icon, index) => (
              <div key={index} className='icon-container'>
                <img 
                  src={icon.image} 
                  className={`marquee-img ${icon.name.toLowerCase().replace(/ /g, '-')}`} 
                  alt={icon.name} 
                />
                <span className='icon-name'>{icon.name}</span>
              </div>
            ))}
          </motion.div>
          {/* <motion.div
            initial={{ x: 0 }}
            animate={{ x: "-100%" }}
            transition={{ 
              duration: 120, 
              repeat: Infinity, 
              ease: 'linear' 
            }}
            className='marquee-div'
          >
            {iconsRow1.concat(iconsRow1).map((icon, index) => (
              <div key={index} className='icon-container'>
                <img 
                  src={icon.image} 
                  className={`marquee-img ${icon.name.toLowerCase().replace(/ /g, '-')}`} 
                  alt={icon.name} 
                />
                <span className='icon-name'>{icon.name}</span>
              </div>
            ))}
          </motion.div> */}
        </div>
        <div style={{display:'flex', justifyContent:"flex-end"}}>
          <h5 style={{marginTop:"50px", textAlign:"right"}} >Smart Contract Languages & Frameworks</h5>
        </div>
        <div className='marquee-container'>
          <motion.div
            initial={{ x: 0 }}
            animate={{ x: "100%" }}
            transition={{ 
              duration: 120, 
              repeat: Infinity, 
              ease: 'linear' 
            }}
            className='marquee-div'
          >
            {iconsRow2.concat(iconsRow2).map((icon, index) => (
              <div key={index} className='icon-container'>
                <img 
                  src={icon.image} 
                  className={`marquee-img ${icon.name.toLowerCase().replace(/ /g, '-')}`} 
                  alt={icon.name} 
                />
                <span className='icon-name'>{icon.name}</span>
              </div>
            ))}
          </motion.div>
          <motion.div
            initial={{ x: 0 }}
            animate={{ x: "100%" }}
            transition={{ 
              duration: 120, 
              repeat: Infinity, 
              ease: 'linear' 
            }}
            className='marquee-div'
          >
            {iconsRow2.concat(iconsRow2).map((icon, index) => (
              <div key={index} className='icon-container'>
                <img 
                  src={icon.image} 
                  className={`marquee-img ${icon.name.toLowerCase().replace(/ /g, '-')}`} 
                  alt={icon.name} 
                />
                <span className='icon-name'>{icon.name}</span>
              </div>
            ))}
          </motion.div>
          {/* <motion.div
            initial={{ x: 0 }}
            animate={{ x: "100%" }}
            transition={{ 
              duration: 120, 
              repeat: Infinity, 
              ease: 'linear' 
            }}
            className='marquee-div'
          >
            {iconsRow2.concat(iconsRow2).map((icon, index) => (
              <div key={index} className='icon-container'>
                <img 
                  src={icon.image} 
                  className={`marquee-img ${icon.name.toLowerCase().replace(/ /g, '-')}`} 
                  alt={icon.name} 
                />
                <span className='icon-name'>{icon.name}</span>
              </div>
            ))}
          </motion.div> */}
        </div>
      </div>
    </div>
    </>
  );
}

export default OurNetworks;
