import React from 'react'
import WhoWeAre2 from './WhoWeAre2'
import OurTeam from './OurTeam'
import FullCycle from './FullCycle'
import LatestBlogs from './LatestBlogs'
import './ServiceInternalPage.css'
import serviceImage from '../images/about web5 solution.png';
import rectangle70Image from '../images/imagemission.jpg';
import CustomButton from './CustomButton'
import RelatedServices from './RelatedServices'
import StudyCase from './StudyCase'

function ServiceInternalPage() {
  return (
    <div>
        <div className='serviceInternal-header'>
            
            <div className='serviceInternal-content'>
                <h3>NFT Development Service</h3>
                <p>Looking to build your own NFT marketplace from the ground up? Web5Solution is here to help! We specialize in creating unique NFT solutions tailored to your needs. Our team is ready to turn your ideas into a top-notch NFT marketplace, packed with all the features you want and executed to perfection.</p>
                <button>Let’s chat</button>
            </div>
            <div className='serviceInternal-Image'><img src={serviceImage}/></div>
        </div>
        <div className='serviceInternal-div'>
            <div style={{height:"450px",
                width:"50%"
            }}><img src={rectangle70Image} style={{height:"450px" , width:"100%"}}/></div>
            <div  style={{width:"50%"
           } }><h2>NFT Development Service</h2>
            <p>Let’s work together to bring your marketplace to life. At Web5Soltuion, we’re all about turning your ideas into a seamless, feature-packed NFT platform. Our experts are eager to guide you through every stage of development, ensuring your marketplace shines in the digital space. Reach out today and let’s start building something extraordinary!</p>
            <CustomButton buttonText='Know More'/>
            </div>
        </div>
        <RelatedServices/>
        <OurTeam/>
        <WhoWeAre2/>
        <StudyCase/>
    <FullCycle/>
    <LatestBlogs/>
    </div>
  )
}

export default ServiceInternalPage