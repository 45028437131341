import React from 'react';
import { useParams } from 'react-router-dom';
import BlogCard1 from './BlogCard1';
import BlogCard2 from './BlogCard2';
import BlogCard3 from './BlogCard3';
import BlogCard4 from './BlogCard4';

const CardDetail = () => {
  const { cardId } = useParams();

  const renderCardContent = () => {
    switch (cardId) {
      case 'card1':
        return <BlogCard1 />;
      case 'card2':
        return <BlogCard2 />;
      case 'card3':
        return <BlogCard3 />;
      case 'card4':
        return <BlogCard4 />;
      default:
        return <div>Card not found</div>;
    }
  };

  return (
    <div>
      {renderCardContent()}
    </div>
  );
};

export default CardDetail;
