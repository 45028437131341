import React, { useState } from 'react';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import { MultiSelect } from 'react-multi-select-component';
import aiIntelligenceImage from '../images/blockchain-network.svg';
import smartImage from '../images/smart-contract.svg';
import contractImage from '../images/woman-looking-at-contract-audit.svg';
import cloudserverImage from '../images/cloud-server-data-security-lock.svg';
import icoImage from '../images/ico.svg';
import cryptoImage from '../images/crypto-wallet-application.svg';
import cryptoexchangeImage from '../images/cryptocurrency-exchange-platform.svg';
import nftImage from '../images/nft-marketplace.svg';
import nftokenImage from '../images/nft-token.svg';
import nftgameImage from '../images/nft-game.svg';
import metaImage from '../images/metaverse-software-developer.svg';
import gameImage from '../images/girl-enjoys-playing-vr-games.svg';
import earnImage from '../images/play-to-earn.svg';
import ethereumImage from '../images/ethereum (1).svg';
import decentralImage from '../images/man-working-with-decentralized-application.svg';
import blockDevImage from '../images/blockchain-development.svg';
import defiImage from '../images/defi-decentralized-finance-and-blockchain-technology.svg';
import './Services.css';
import Cards from './Cards';
import ChooseUs from './ChooseUs';
import OurTeam from './OurTeam';
import { Helmet } from 'react-helmet';

const services = [
  { title: "Blockchain Networks", description: "We specialize in Ethereum, Polygon, Solana, Binance, Hyperledger, and Cardano. Our expertise in these networks ensures robust and scalable blockchain solutions tailored to your needs.", image: aiIntelligenceImage },
  { title: "Smart Contracts Development", description: "Our team excels in developing secure, efficient, and reliable smart contracts, ensuring seamless automated transactions on your blockchain platform.", image: smartImage },
  { title: "Smart Contracts Audit", description: "We provide comprehensive smart contract audits to ensure security and efficiency, identifying vulnerabilities and optimizing performance.", image: contractImage },
  { title: "Solidity Development", description: "Our Solidity developers create robust and secure smart contracts tailored to your specific blockchain requirements.", image: cloudserverImage },
  { title: "ICO, IEO, STO", description: "We offer end-to-end services for Initial Coin Offerings (ICO), Initial Exchange Offerings (IEO), and Security Token Offerings (STO), helping you raise funds securely.", image: icoImage },
  { title: "Crypto Wallet", description: " We develop secure, user-friendly crypto wallets, ensuring safe storage and management of digital assets.", image: cryptoImage },
  { title: "Crypto Exchange", description: "Our team builds high-performance crypto exchanges, providing secure and efficient trading platforms for digital assets.", image: cryptoexchangeImage },
  { title: "NFT Marketplaces", description: " We create feature-rich NFT marketplaces for seamless buying, selling, and trading of digital assets.", image: nftImage },
  { title: "NFT Game", description: "Our expertise in NFT game development ensures immersive gaming experiences with blockchain integration.", image: nftokenImage },
  { title: "NFT Tokens", description: " We assist in creating and managing NFT tokens, enabling unique digital ownership and monetization.", image: nftgameImage },
  { title: "Metaverse Development", description: " Dive into the metaverse with our development services, creating virtual worlds that offer immersive digital experiences.", image: metaImage },
  { title: "Metaverse Games", description: "We develop engaging metaverse games that integrate blockchain technology for enhanced gameplay and virtual economies.", image: gameImage },
  { title: "Blockchain Game Development", description: "Our team develops innovative blockchain games, combining gaming with decentralized technology for unique player experiences.", image: earnImage },
  { title: "Ethereum Token", description: "We specialize in creating Ethereum tokens, ensuring compliance with standards like ERC-20 and ERC-721 for various use cases.", image: ethereumImage },
  { title: "Blockchain Development", description: "From private blockchains to public networks, we provide comprehensive blockchain development services tailored to your business.", image: blockDevImage },
  { title: "dApps", description: "We build decentralized applications (dApps) that run on blockchain networks, offering transparency, security, and user-centric features.", image: decentralImage },
  { title: "DeFi", description: "Our DeFi development services enable decentralized finance applications, providing secure and transparent financial solutions.", image: defiImage },
];

const Services = () => {
  const [selectedCategory, setSelectedCategory] = useState("All");
  const [showButtons] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [buttonStartIndex, setButtonStartIndex] = useState(0);
  const cardsPerPage = 10;
  const buttonsPerPage = 4;
  const [selectedOptions, setSelectedOptions] = useState([]);

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
    setCurrentPage(1);
  };

  // const handleSearchChange = (event) => {
  //   setSearchTerm(event.target.value);
  // };

  const filteredServices = selectedCategory === "All" && selectedOptions.length === 0
    ? services
    : services.filter(service => 
        selectedCategory === "All" 
        ? selectedOptions.some(option => option.value === service.title)
        : service.title === selectedCategory
      );

  const indexOfLastService = currentPage * cardsPerPage;
  const indexOfFirstService = indexOfLastService - cardsPerPage;
  const currentServices = filteredServices.slice(indexOfFirstService, indexOfLastService);

  const handleNextButtons = () => {
    if (buttonStartIndex + buttonsPerPage < services.length) {
      setButtonStartIndex(buttonStartIndex + buttonsPerPage);
    }
  };

  const handlePreviousButtons = () => {
    if (buttonStartIndex - buttonsPerPage >= 0) {
      setButtonStartIndex(buttonStartIndex - buttonsPerPage);
    }
  };

  const displayedButtons = services.slice(buttonStartIndex, buttonStartIndex + buttonsPerPage);

  const options = services.map(service => ({
    label: service.title,
    value: service.title
  }));
  const totalPages = Math.ceil(filteredServices.length / cardsPerPage);
  return (
    <div className="services">
      <Helmet>
        <title>Blockchain Solutions for Enterprise Growth</title>
        <meta name="description" content="Experience the greatness of blockchain
technology with Web5solution. By improving the security, transparency,
and efficiency of enterprise operations, our specialized blockchain
solutions help the business grow." />
        <meta name="keywords" content="web5solution,web5, Blockchain, Crypto" />
      </Helmet>
      <h2>Select Our Services</h2>
      <div className={`service-buttons ${showButtons ? 'show' : 'hide'}`}>
        <button className="arrow-button" onClick={handlePreviousButtons} disabled={buttonStartIndex === 0}
          style={{
            backgroundColor: buttonStartIndex === 0 ? "#ddd" : "#22A8B3",
            color: buttonStartIndex === 0 ? "#22A8B3" : "white"
          }}
        >
          <FaArrowLeft />
        </button>
        <div className="all-buttons">
          <button
            onClick={() => handleCategoryClick("All")}
            style={{
              backgroundColor: selectedCategory === "All" ? "#22A8B3" : "white",
              color: selectedCategory === "All" ? "white" : "#22A8B3"
            }}
          >
            All
          </button>
          {displayedButtons.map((service, index) => (
            <button
              key={index}
              onClick={() => handleCategoryClick(service.title)}
              style={{
                backgroundColor: selectedCategory === service.title ? "#22A8B3" : "white",
                color: selectedCategory === service.title ? "white" : "#22A8B3"
              }}
            >
              {service.title}
            </button>
          ))}
        </div>
        <button className="arrow-button" onClick={handleNextButtons} disabled={buttonStartIndex + buttonsPerPage >= services.length}
          style={{
            backgroundColor: buttonStartIndex + buttonsPerPage >= services.length ? "#ddd" : "#22A8B3",
            color: buttonStartIndex + buttonsPerPage >= services.length ? "#22A8B3" : "white"
          }}
        >
          <FaArrowRight />
        </button>
      </div>

      <div className="mobile-dropdown">
        <div className="checkboxes">
          <MultiSelect
            options={options}
            value={selectedOptions}
            onChange={setSelectedOptions}
            labelledBy="Select services"
            overrideStrings={{
              "selectSomeItems": "Our Services",
              "allItemsAreSelected": "All services selected",
              "selectAll": "Select all",
              "search": "Search services",
              "clearSearch": "Clear search"
            }}
          />
        </div>
      </div>

      <div className="regular-view">
        <div className="card-grid">
          {currentServices.map((service, index) => (
            <Cards
              key={index}
              title={service.title}
              description={service.description}
              image={service.image}
            />
          ))}
        </div>
        <div className="pagination">
          <button className='left-page-btn2 '
            onClick={() => setCurrentPage(currentPage - 1)}
            disabled={currentPage === 1}
          >
            <FaArrowLeft />
          </button>
          {Array.from({ length: totalPages }, (_, index) => (
            <button
              key={index}
              onClick={() => setCurrentPage(index + 1)}
              className={`page-number2 ${currentPage === index + 1 ? 'active' : ''}`}
            >
              {index + 1}
            </button>
          ))}
          <button className='left-page-btn2 '
            onClick={() => setCurrentPage(currentPage + 1)}
            disabled={indexOfLastService >= filteredServices.length}
          >
            <FaArrowRight />
          </button>
        </div>
      </div>

      <ChooseUs />
      <OurTeam />
    </div>
  );
};

export default Services;
